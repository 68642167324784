<template>
  <div style="min-height:100vh;padding:10px">
    <titleLink title="我的备案" :onClick="onClickBack"></titleLink>
    <card style="margin-top:.4rem" v-if="!isEdit">
      <template #content>
        <tourInfoTop :tour="tour"/>
        <van-divider dashed/>
        <van-cell title="游客姓名" :value="backup.user.username"/>
        <van-cell title="游客手机" :value="backup.user.mobile"/>
        <van-cell title="备案手机" :value="backup.mobile"/>
        <van-cell title="入住地址" :value="backup.live_address"/>
        <van-cell title="行程" :value="backup.travel_list.list_name"/>
        <van-cell title="备案时间" :value="backup.created_at"/>
      </template>
    </card>
    <card style="margin-top:.4rem" v-if="isEdit">
      <template #content>
        <van-cell title="游客姓名" :value="backup.user.username"/>
        <van-cell title="游客手机" :value="backup.user.mobile"/>
        <van-cell title="行程" :value="backup.travel_list.list_name"/>
        <fieldForm
          ref="form"
          :submit="save"
          :modal="backupData"
          :fieldData="fields"
          submitBtnText="保存"
          v-on:formUpdate="onFormUpdate"
        ></fieldForm>
        <van-cell title="备案时间" :value="backup.created_at"/>
      </template>
    </card>
    <div class="action_bg" v-if="!isEdit">
      <van-button type="info" color="#395EE8" 
        class="action_btn" @click="isEdit = true" round>修改备案信息</van-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import card from '@/views/components/Card'
import tourInfoTop from '@/views/components/TourInfoTop'
import fieldForm from "@/views/components/FieldForm"
import api from '@/services/apis.js'
import titleLink from "@/views/components/TitleLink"
import {
  validatePhone
} from "@/utils/validators"
export default {
  components: {
    card, tourInfoTop, fieldForm, titleLink
  },
  data() {
    const _self = this
    return {
      isEdit: false,
      backupData: {
        id: 0,
        travel_list_id: 0,
        destination_address: "",
        live_address: "",
        mobile: ""
      },
      fields: [
        {
          title: "备案手机",
          name: "mobile",
          type: "text",
          disabled: false,
          rule: [
            { validator: validatePhone, message: "请输入正确的手机号"},
            { required: true, message: "" },
          ],
          inline: false,
        },
        {
          title: "入住地址",
          name: "live_address",
          type: "text",
          disabled: false,
          rule: [{ required: true, message: "" }],
          inline: false,
        },
      ]
    }
  },
  computed: {
    ...mapGetters('travel_list', {
      backup: 'currentTourBackup',
      tour: 'currentTravelList'
    })
  },
  mounted(){
    const _self = this
    const tourId = this.$route.params.id
    this.$store.dispatch('travel_list/getCurrentTourBackup', tourId).then((res) => {
      _self.backupData.id = res.id
      _self.backupData.travel_list_id = tourId
      _self.backupData.destination_address = res.end_addr
      _self.backupData.mobile = res.user.mobile
      _self.backupData.live_address = res.live_address
    })
    this.$store.dispatch('travel_list/getTravelListInfoById', tourId)
  },
  methods: {
    onClickBack() {
      this.$router.go(-1)
    },
    save() {
      const _self = this
      api.update_travel_lists_archive_info(this.backupData).then((res) => {
        if(res.status === 200) {
          _self.$store.dispatch('travel_list/getCurrentTourBackup', _self.backupData.travel_list_id)
          _self.isEdit = false
        }
      })
    },
    onFormUpdate(data) {
      this.backupData.destination_address = data.destination_address
      this.backupData.mobile = data.mobile
      this.backupData.live_address = data.live_address
    }
  }
}
</script>

<style scoped>
.action_btn {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  padding-top: 10px;
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 666;
}
</style>